








































































































































































































































import Vue from 'vue';
import LearningPathResourceViewModel from '@/src/services/viewModel/resource/LearningPathResourceViewModel';
import LookupLevelRequestViewModel from '@/src/services/viewModel/request/LearnitBaseData/LookupLevelRequestViewModel';
import LookupContentStatusRequestViewModel from '@/src/services/viewModel/request/LearnitBaseData/LookupContentStatusRequestViewModel';
import GetLearningPathUnitByLearningPathPagedListRequestViewModel from '@/src/services/viewModel/request/LearnitPaths/GetLearningPathUnitByLearningPathPagedListRequestViewModel';
import UploadDocumentRequestViewModel from '@/src/services/viewModel/request/Documents/UploadDocumentRequestViewModel';
import LookupContentTypeRequestViewModel from '@/src/services/viewModel/request/LearnitBaseData/LookupContentTypeRequestViewModel';
import LearningPathUnitListResourceViewModel from '@/src/services/viewModel/resource/LearningPathUnitListResourceViewModel';
import GetLearningPathUnitPagedListRequestViewModel from '@/src/services/viewModel/request/LearnitUnits/GetLearningPathUnitPagedListRequestViewModel';
import PaginationDataResourceViewModel from '@/src/services/viewModel/resource/PaginationDataResourceViewModel';
import SetSortRequestViewModel from '@/src/services/viewModel/resource/SetSortRequestViewModel';
import LearnitTagResourceViewModel from '@/src/services/viewModel/resource/LearnitTagResourceViewModel';
import LookupTagRequestViewModel from '@/src/services/viewModel/request/LearnitTags/LookupTagRequestViewModel';
import LearningPathTagResourceViewModel from '@/src/services/viewModel/resource/LearningPath_TagResourceViewModel';
import UploadZipDocumentRequestViewModel from '@/src/services/viewModel/request/Documents/UploadZipDocumentRequestViewModel';
import GetFoldersRequestViewModel from '@/src/services/viewModel/request/Documents/GetFoldersRequestViewModel';

export default Vue.extend({
  auth: true,
  role: ['Admin', 'Hostadmin'],
  data: () => ({
    unassignedTags: [] as LearnitTagResourceViewModel[],
    assignedTags: [] as LearnitTagResourceViewModel[],
    filterUnassignedTags: new LookupTagRequestViewModel(),
    isLoadingTags: false,
    isAddTagDialogVisible: false,
    tagIdToAdd: undefined as number | undefined,
    isLoadingUnits: false,
    unassignedLearningUnits: [] as LearningPathUnitListResourceViewModel[],
    filterUnassignedLearningUnits: new GetLearningPathUnitPagedListRequestViewModel(),
    pagingUnassignedUnits: {} as PaginationDataResourceViewModel,
    isAddLearningUnitDialogVisible: false,
    learningUnitIdToAdd: undefined as number | undefined,
    createOrUpdateLearningPath: new LearningPathResourceViewModel(),
    levels: [] as any[],
    authoringFolders: [] as any[],
    status: [] as any,
    assignedLearningUnits: [] as LearningPathUnitListResourceViewModel[],
    columns: [] as {}[],
    tagColumns: [] as {}[],
    isLoading: false,
    picture: [] as File[],
    errorResponse: undefined as any,
    uploadDialogErrorResponse: undefined as any,
    successMessage: undefined as string | undefined,
    uploadFolderSuccessMessage: undefined as string | undefined,
    contentTypes: [] as any[],
    isAddFolderVisible: false,
    zipFile: [] as File[],
    uploadFolderName: undefined as string | undefined,

  }),
  computed: {
    // isUploadFolderNameValid(): boolean {
    //   if (this.validatedFolderName?.match(/^[a-zA-Z-1-9-]+$/g)) {
    //     return true;
    //   }
    //   return false;
    // },
    assignedTagsIds(): number[] {
      return this.assignedTags.map((tag) => (tag.id as any)?.value ?? tag.id).filter((id) => this._.isSet(id));
    },
    assignedLearningUnitIds(): number[] {
      return this.assignedLearningUnits.map((lu) => (lu.id as any)?.value ?? lu.id).filter((id) => this._.isSet(id));
    },
    learningPathId() {
      const { learningPathId } = this.$router.currentRoute.params;
      if (learningPathId) {
        return parseInt(learningPathId, 10);
      }
      return undefined;
    },
    isEditMode(): boolean {
      if (this.learningPathId) return true;
      return false;
    },
    pageTitle(): string {
      if (this.createOrUpdateLearningPath.title && this.createOrUpdateLearningPath.title.length > 0) {
        return this.createOrUpdateLearningPath.title;
      }
      if (this.isEditMode) {
        return this.$t('page.learningPaths.title.edit');
      }
      return this.$t('page.learningPaths.title.create');
    },
  },
  watch: {
    filterUnassignedLearningUnits: {
      deep: true,
      handler() {
        this.loadUnassignedLearningUnits();
      },
    },
    filterUnassignedTags: {
      deep: true,
      handler() {
        this.loadUnassignedTags();
      },
    },

  },
  async mounted() {
    this.isLoading = true;
    this.columns = [
      // { value: '', field: 'sort-actions' },
      { value: this.$t('page.learningPaths.table.shortCode'), textAlign: 'left', field: 'shortCode' },
      { value: this.$t('page.learningPaths.table.title'), textAlign: 'left', field: 'title' },
      { value: this.$t('page.learningPaths.table.subTitle'), textAlign: 'left', field: 'subTitle' },
      { value: '', field: 'actions', width: '1px' },
    ];
    this.tagColumns = [
      { value: 'Titel', textAlign: 'left', field: 'caption' },
      { value: '', field: 'actions', width: '1px' },
    ];

    const levels = await this.$service.api.learnitBaseData.lookupLevel(new LookupLevelRequestViewModel());
    const status = await this.$service.api.learnitBaseData.lookupContentStatus(new LookupContentStatusRequestViewModel());
    const contentTypes = await this.$service.api.learnitBaseData.lookupContentType(new LookupContentTypeRequestViewModel());
    this.levels = levels.items;
    this.status = status.items;
    this.contentTypes = contentTypes.items;

    if (this.learningPathId) {
      this.createOrUpdateLearningPath = await this.$service.api.learnitPaths.getLearningPathById(this.learningPathId);
      await this.loadAssignedLearningUnits();
      await this.loadAssignedTags();
      await this.getAuthoringToolFolders();
    }

    // filehandling
    if (this.createOrUpdateLearningPath.pictureDocumentId) {
      try {
        const blob = await this.$service.api.documents.downloadDocument(this.createOrUpdateLearningPath.pictureDocumentId);
        const image = new Blob([blob], { type: 'image/*' });
        this.picture.push(image as File);
      } catch {
        this.createOrUpdateLearningPath.pictureDocumentId = undefined;
      }
    }
    // await this.loadUnassignedLearningUnits();
    this.isLoading = false;
  },
  methods: {
    async loadAssignedLearningUnits() {
      const response = await this.$service.api.learnitPaths.getLearningPathUnitByLearningPathPagedList(this.learningPathId!, new GetLearningPathUnitByLearningPathPagedListRequestViewModel());
      this.assignedLearningUnits = response.items.map((e: any) => ({ ...e.toDTO() }));
      await this.loadUnassignedLearningUnits();
    },
    async loadUnassignedLearningUnits() {
      const response = await this.$service.api.learnitUnits.getLearningPathUnitPagedList(this.filterUnassignedLearningUnits);
      this.unassignedLearningUnits = response.items.map((e : any) => ({ ...e.toDTO() }));
      this.unassignedLearningUnits = this.unassignedLearningUnits.filter((i) => !this.assignedLearningUnitIds.includes(i.id!));
    },
    showAddLearningUnitsDialog() {
      this.isAddLearningUnitDialogVisible = true;
    },
    async addLearningUnit(entry : any, sort: number) {
      this.isLoadingUnits = true;
      this.learningUnitIdToAdd = undefined;
      this.isAddLearningUnitDialogVisible = false;
      entry.sort = sort;
      const sortNumber = new SetSortRequestViewModel().fromModel({
        sort: entry.sort!,
      });
      if (this._.isSet(this.learningPathId)) {
        await this.$service.api.learnitPaths.addLearningUnit(this.learningPathId, entry.id);
        await this.$service.api.learnitPaths.updateLearningUnitSort(this.learningPathId!, entry.id, sortNumber);
      }
      this.assignedLearningUnits.push(entry);
      await this.loadUnassignedLearningUnits();
      this.isLoadingUnits = false;
    },
    async removeLearningUnit(entry : any) {
      this.isLoadingUnits = true;
      if (this._.isSet(this.learningPathId)) {
        await this.$service.api.learnitPaths.removeLearningUnit(this.learningPathId!, entry.id);
      }
      this.$set(this, 'assignedLearningUnits', this.assignedLearningUnits.filter((i) => ((i as any).id?.value ?? i.id) !== entry.id));
      await this.loadAssignedLearningUnits();
      this.isLoadingUnits = false;
    },
    async moveLearningUnit(entry : any, index: number, deltaPosition: number) {
      this.isLoadingUnits = true;
      const aboveOrBelowLearningUnit = this.assignedLearningUnits[index - deltaPosition];
      const aboveOrBelowLearningUnitSort = (aboveOrBelowLearningUnit as any)?.sort?.value as number | undefined ?? aboveOrBelowLearningUnit?.sort;
      const aboveOrBelowLearningUnitId = (aboveOrBelowLearningUnit as any)?.id?.value as number | undefined ?? aboveOrBelowLearningUnit?.id;
      const sort = new SetSortRequestViewModel().fromModel({
        sort: aboveOrBelowLearningUnitSort,
      });
      const sortAboveOrBelowLearningUnit = new SetSortRequestViewModel().fromModel({
        sort: entry.sort!,
      });
      await this.$service.api.learnitPaths.updateLearningUnitSort(this.learningPathId!, entry.id, sort);
      await this.$service.api.learnitPaths.updateLearningUnitSort(this.learningPathId!, aboveOrBelowLearningUnitId!, sortAboveOrBelowLearningUnit);
      await this.loadAssignedLearningUnits();
      this.isLoadingUnits = false;
    },
    editLearningUnit(entry: any) {
      this.$router.push(`/ktgl/learnit/learningUnits/${entry.id}/edit`);
    },
    goToLearningPathOverview() {
      this.$router.push('/ktgl/learnit/learningPaths');
    },
    cancel() {
      this.$router.back();
    },
    async deleteLearningPath() {
      this.isLoading = true;
      try {
        const isDeleteConfirmed = await this.$dialog.confirm(this.$t('app.delete.confirmation.title'), this.$t('app.delete.confirmation.text'));
        if (isDeleteConfirmed) {
          await this.$service.api.learnitPaths.deleteLearningPath(this.learningPathId!);
          this.$router.push('/ktgl/learnit/learningPaths/');
        }
      } catch (error) {
        this.$log.error(error);
        this.errorResponse = error;
      }
      this.isLoading = false;
    },
    async save() {
      this.errorResponse = undefined as any;
      if (!this.createOrUpdateLearningPath.validate()) {
        return;
      }
      try {
        if (this.learningPathId) {
          await this.$service.api.learnitPaths.updateLearningPath(this.learningPathId, this.createOrUpdateLearningPath);
          this.successMessage = this.$t('page.learningPaths.successMessage.updateLearningPath');
        } else {
          const id = await this.$service.api.learnitPaths.createLearningPath(this.createOrUpdateLearningPath);
          if (this._.isSet(id)) {
            this.assignedLearningUnitIds.forEach((unitId) => {
              this.$service.api.learnitPaths.addLearningUnit(id!, unitId);
            });
            this.successMessage = this.$t('page.learningPaths.successMessage.createLearningPath');
            await this.$router.replace(`/ktgl/learnit/learningPaths/${id}/edit`);
          } else {
            throw new Error('Unable to create new LearningPath');
          }
        }
      } catch (error : any) {
        this.errorResponse = error;
      }
    },
    async onFileSelected(files: File[]) {
      const [file] = files;
      let id = undefined as number | undefined;
      if (file) {
        const req = new UploadDocumentRequestViewModel();
        req.uploadFile = file;
        id = await this.$service.api.documents.uploadDocument(req);
        this.createOrUpdateLearningPath.pictureDocumentId = id;
      }
    },
    async loadUnassignedTags() {
      const response = await this.$service.api.learnitTags.lookupTag(this.filterUnassignedTags);
      this.unassignedTags = response.items.map((e : any) => ({ ...e.toDTO() }));
      this.unassignedTags = this.unassignedTags.filter((i) => !this.assignedTagsIds.includes(i.id!));
    },
    async loadAssignedTags() {
      const response = await this.$service.api.learnitPaths.getTagsByLearningPathId(this.learningPathId!);
      this.assignedTags = response.items.map((e: any) => ({ ...e.toDTO() }));
      await this.loadUnassignedTags();
    },
    async addTag(entry : any) {
      this.isLoadingTags = true;
      this.tagIdToAdd = undefined;
      this.isAddTagDialogVisible = false;
      if (this._.isSet(this.learningPathId)) {
        const o = new LearningPathTagResourceViewModel();
        o.learnitTagId = entry?.id!;
        o.learningPathId = this.learningPathId;
        await this.$service.api.learnitPaths.addTagToLearningPath(o);
      }
      this.assignedTags.push(entry);
      await this.loadUnassignedTags();
      this.isLoadingTags = false;
    },
    // textToSlug(text: string) {
    //   return text
    //     .toLowerCase()
    //     .trim()
    //     .replace(/[^\w\s-]/g, '')
    //     .replace(/[\s_-]+/g, '-')
    //     .replace(/^-+|-+$/g, '');
    // },
    async removeTag(entry : any) {
      this.isLoadingTags = true;
      if (this._.isSet(this.learningPathId)) {
        await this.$service.api.learnitPaths.removeTagFromLearningPath(this.learningPathId!, entry.id);
      }
      this.$set(this, 'assignedTags', this.assignedTags.filter((t) => ((t as any).id?.value ?? t.id) !== entry.id));
      await this.loadAssignedTags();
      this.isLoadingTags = false;
    },
    async openUploadFolderDialog() {
      this.isAddFolderVisible = true;
      this.uploadFolderName = this.createOrUpdateLearningPath.shortCode;
    },
    async uploadZipDocument(files: File[], folderName: string | undefined) {
      this.isLoading = true;
      const [file] = files;
      const o = new UploadZipDocumentRequestViewModel();
      o.entityId = this.learningPathId;
      o.entityType = 'paths';
      o.uploadFilename = folderName;
      o.uploadFile = file!;
      try {
        await this.$service.api.learnitPaths.updateLearningPath(this.learningPathId!, this.createOrUpdateLearningPath);
        await this.$service.api.documents.uploadZipDocument(o);
        this.createOrUpdateLearningPath = await this.$service.api.learnitPaths.getLearningPathById(this.learningPathId!);
        await this.getAuthoringToolFolders();
        this.uploadFolderSuccessMessage = this.$t('app.unitOrPath.uploadAuthoringTool.label.uploadSuccessMessage');
        this.isAddFolderVisible = false;
      } catch (error) {
        this.$log.error(error);
        this.uploadDialogErrorResponse = error;
      }
      this.isLoading = false;
    },
    async getAuthoringToolFolders() {
      const o = new GetFoldersRequestViewModel();
      o.entityId = this.learningPathId;
      o.entityType = 'paths';
      try {
        const result = await this.$service.api.documents.getFolders(o);
        this.authoringFolders = (result.items ?? []).map((i, index) => ({
          id: i,
          caption: i,
          details: i,
          sort: index,
        }));
      } catch (error) {
        this.$log.error(error);
        this.errorResponse = error;
      }
    },
  },
});
