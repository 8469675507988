// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2022 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import UploadZipDocumentRequestDTO from '../../dto/request/UploadZipDocumentRequestDTO';

export default abstract class UploadZipDocumentRequestModel extends BaseModel<UploadZipDocumentRequestDTO> {
  // #region    ──FormRequest──────────────────────────╮
  /**
  * @type {binary}
  */
  get uploadFile() { return this.dto.uploadFile; }
  set uploadFile(value) { this.dto.uploadFile = value; }
  // #endregion ───────────────────────────────────────╯
  // #region    ──HeaderRequest──────────────────────────╮
  // #endregion ─────────────────────────────────────────╯
  // #region    ──QueryRequest──────────────────────────╮
  /**
  */
  get uploadFilename() { return this.dto.uploadFilename; }
  set uploadFilename(value) { this.dto.uploadFilename = value; }
  /**
  */
  get entityType() { return this.dto.entityType; }
  set entityType(value) { this.dto.entityType = value; }
  /**
  * @type {int64}
  */
  get entityId() { return this.dto.entityId; }
  set entityId(value) { this.dto.entityId = value; }
  // #endregion ────────────────────────────────────────╯

  protected transformFromQuery(queries: Record<string, string>): void {
    let value: any;
    this.dto.uploadFilename = queries.uploadFilename;
    this.dto.entityType = queries.entityType;
    value = Number.parseFloat(queries.entityId);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.entityId = value;
  }
}
