// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2022 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import SetSortRequestDTO from '../../dto/resource/SetSortRequestDTO';

export default abstract class SetSortRequestModel extends BaseModel<SetSortRequestDTO> {
  /**
  * @type {int64}
  */
  get sort() { return this.dto.sort; }
  set sort(value) { this.dto.sort = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    let value: any;
    value = Number.parseFloat(queries.sort);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.sort = value;
  }
}
